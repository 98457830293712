import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/james/MedGrocer/nn-pap/src/components/Layout/Layout.js";
import { Link } from "gatsby";
import Container from "layout/Container";
import SEO from "layout/SEO";
import Collapsible from "elements/Collapsible";
import { Brand, ProgramName } from "elements/Brand";
import ContactUs from '../components/StaticPages/HelpCenter/ContactUs';
import DeliveryDisclaimerMessage from 'elements/DeliveryDisclaimerMessage';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Container isCentered mdxType="Container">
      <SEO title="Help Center" mdxType="SEO" />
      <h2>{`Frequently Asked Questions`}</h2>
      <hr />
      <h4>{`Doctor Enrollment`}</h4>
      <Collapsible title="Am I eligible for the program?" mdxType="Collapsible">
        <p>{`All doctors nominated by Novo Nordisk Pharmaceuticals (Philippines), Inc. are eligible to join this program.`}</p>
      </Collapsible>
      <Collapsible title="How do I become a Partner Doctor?" mdxType="Collapsible">
        <p>{`To become a Partner Doctor, fill out the online Doctor Enrollment Form at `}<Link to="/doctor/sign-up" className="has-text-weight-bold has-text-primary" mdxType="Link">{`ozempic.medgrocer.com/doctor/sign-up`}</Link>{`. You will receive an SMS confirming your eligibility and activating your account by the next working day. Once you receive the SMS, you may start nominating patients to enroll in the program. `}</p>
      </Collapsible>
      <Collapsible title="How do I instruct patients to enroll in the program?" mdxType="Collapsible">
        <p>{`You may nominate your patients in two different ways:`}</p>
        <div className="table-container">
          <table className="table is-bordered is-fullwidth">
            <thead>
              <tr>
                <th className="has-text-weight-bold">Share the link</th>
                <th className="has-text-weight-bold">Issue an ePrescription</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ask your patients to fill out the enrollment form and upload their prescription in <Link to="/patient/enroll" className="has-text-weight-bold has-text-primary" mdxType="Link">ozempic.medgrocer.com/patient/enroll</Link></td>
                <td>Use MedGrocer’s website to issue a <Brand mdxType="Brand" /> prescription and forward it to your patient via SMS</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Collapsible>
      <h4>{`Patient Enrollment`}</h4>
      <Collapsible title="Am I eligible for the program?" mdxType="Collapsible">
        <i>Inclusion Criteria</i>
        <ul>
          <li parentName="ul">{`Any adult patient (18 years old and above) with type 2 diabetes (preferably GLP-1 and insulin naïve)`}</li>
          <li parentName="ul">{`Has a valid prescription for `}<Brand mdxType="Brand" />{` from an enrolled doctor`}</li>
          <li parentName="ul">{`Resides or is able to receive the medicines within our service areas`}</li>
          <li parentName="ul">{`In accordance with local approved indications`}<p className="help">
  You may request full prescribing information at{" "}
  <a href="mailto:NNPPISafety@novonordisk.com" className="is-size-7">
    NNPPISafety@novonordisk.com
  </a>
  .
            </p></li>
        </ul>
        <i>Exclusion Criteria</i>
        <ul>
          <li parentName="ul">
            <p parentName="li">{`Persons with known hypersensitivity to `}<Brand mdxType="Brand" />{` or any of its excipients`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Persons with any of the following conditions:`}<br /></p>
            <ul parentName="li">
              <li parentName="ul">{`Congestive Heart Failure NHYA Class IV`}</li>
              <li parentName="ul">{`Severe hepatic impairment (Child’s Pugh C)`}</li>
              <li parentName="ul">{`Severe renal impairment (Chronic Kidney Disease stage 4 to 5)`}</li>
              <li parentName="ul">{`Inflammatory bowel disease and diabetic gastroparesis`}</li>
              <li parentName="ul">{`Pregnant and lactating women`}</li>
            </ul>
          </li>
        </ul>
        <p>{`Reference: `}<Brand mdxType="Brand" />{` PhFDA-Approved Full Prescribing Information © October 2018.`}</p>
      </Collapsible>
      <Collapsible title="How do I become an enrollee?" mdxType="Collapsible">
        <p>{`To become an enrollee, your doctor must first be enrolled in the program. Your doctor will provide a link where you can input your enrollment and delivery details.  `}<DeliveryDisclaimerMessage size={6} mdxType="DeliveryDisclaimerMessage" /></p>
        <p>{`Each enrolled doctor can nominate any number of patients for the program, but only the first five (5) valid enrollees per doctor will be accepted. If capacity frees up, we may allow more enrollments in the future.`}</p>
      </Collapsible>
      <Collapsible title="How would I know if my enrollment went through?" mdxType="Collapsible">
        <p>{`You will receive an SMS verifying your eligibility by the next working day. If you do not receive an SMS from MedGrocer within 48 hours, contact us at `}<a href="mailto:ozempic@medgrocer.com" className="has-text-weight-bold has-text-primary">{`ozempic@medgrocer.com`}</a>{` or 0968 230 6545.`}</p>
      </Collapsible>
      <Collapsible title="What if I don’t want to receive the medicines anymore?" mdxType="Collapsible">
        <p>{`Contact us at `}<a href="mailto:ozempic@medgrocer.com" className="has-text-weight-bold has-text-primary">{`ozempic@medgrocer.com`}</a>{` or 0968 230 6545 to cancel your enrollment.`}</p>
      </Collapsible>
      <Collapsible title="Can I enroll for someone else?" mdxType="Collapsible">
        <p>{`No. Only patients prescribed with `}<Brand mdxType="Brand" />{` can enroll in the program.`}</p>
      </Collapsible>
      <h4>{`Prescription`}</h4>
      <Collapsible title="Do I need a prescription?" mdxType="Collapsible">
        <p>{`Yes. We require a valid `}<Brand mdxType="Brand" />{` prescription that contains the following details: prescription date, patient’s name, doctor’s name, and doctor’s license number.`}</p>
      </Collapsible>
      <Collapsible title="How do I send my prescription?" mdxType="Collapsible">
        <p>{`If your doctor used our built-in ePrescription tool, you should receive a copy of your ePrescription along with your enrollment link. Otherwise, you can upload a picture or a scanned copy of your prescription when you enroll.`}</p>
      </Collapsible>
      <Collapsible title="Do I have to surrender my prescription?" mdxType="Collapsible">
        <p>{`You don’t have to surrender your prescription. However, you must present your prescription to our courier upon delivery.`}</p>
      </Collapsible>
      <Collapsible title="I can’t upload my prescription." mdxType="Collapsible">
        <p>{`You must upload your prescription to avail of the medicines. If you experience any difficulties, contact us at `}<a href="mailto:ozempic@medgrocer.com" className="has-text-weight-bold has-text-primary">{`ozempic@medgrocer.com`}</a>{` or 0968 230 6545 so we can guide you.`}</p>
      </Collapsible>
      <h4>{`Delivery`}</h4>
      <Collapsible title="Is there a convenience fee?" mdxType="Collapsible">
        <p>{`We deliver the medicines free-of-charge.`}</p>
      </Collapsible>
      <Collapsible title="Can I pick up the medicines in the MedGrocer office?" mdxType="Collapsible">
        <p>{`We streamlined our operations to give you a hassle-free experience. Please use our delivery service for your convenience.`}</p>
      </Collapsible>
      <Collapsible title="When will I receive the medicines?" mdxType="Collapsible">
        <p>{`We process enrollments from 9AM to 6PM on weekdays. After enrolling, we will verify your enrollment via SMS. `}<DeliveryDisclaimerMessage size={6} mdxType="DeliveryDisclaimerMessage" /></p>
        <p>{`We will get in touch with you or your authorized representative around this time to confirm the specific delivery date.`}</p>
      </Collapsible>
      <Collapsible title="What if I’m not at home when the medicines arrive?" mdxType="Collapsible">
        <p>{`Your authorized representative should present a copy of your ID and prescription to receive your medicines. If your authorized representative is unavailable, we will contact you if it is possible to reschedule your delivery.`}</p>
      </Collapsible>
      <h4>{`Returns`}</h4>
      <Collapsible title="What is your return policy?" mdxType="Collapsible">
        <p>{`All medicines delivered by MedGrocer are subject to quality checks by our purchasing, storage, assembly, and delivery teams. Please inspect your medicines upon delivery. If your medicines are damaged upon delivery, you may refuse to accept the medicines.`}</p>
        <p>{`On a case-to-case basis, we can process requests to return the medicines within seven days upon delivery. Make sure that you keep your delivery receipt and the medicines you want to return.`}</p>
      </Collapsible>
      <Collapsible title="What medicines will you accept for returns?" mdxType="Collapsible">
        <p>{`We will replace damaged or wrongly dispensed medicines.`}</p>
      </Collapsible>
      <Collapsible title="Can I return an item after seven days?" mdxType="Collapsible">
        <p>{`Any problems encountered seven days after the delivery may not be accommodated under our Return Policy. We highly encourage you to review and inspect your medicines upon delivery so that our courier could immediately facilitate the return, exchange, or refund request.`}</p>
      </Collapsible>
      <h4>{`About the Program`}</h4>
      <Collapsible title="What is the Early Experience Program?" mdxType="Collapsible">
        <p>{`The `}<ProgramName mdxType="ProgramName" />{` is designed to help doctors gain clinical experience in treating patients with diabetes using `}<Brand mdxType="Brand" />{` and to provide patients early access prior to the medicine’s commercial availability.`}</p>
        <p>{`This is NOT a clinical trial program, there will be no collection of data. The program will simply provide early access to patients with diabetes and the benefit of experiencing the novel therapeutic option for reducing diabetes-related complications.`}</p>
        <p>{`The program will run from February 8 to June 30, 2021. Doctor enrollment runs from February 8 to March 15, 2021. Patient enrollment runs from February 8 to April 1, 2021. `}</p>
      </Collapsible>
      <Collapsible title="What are the benefits of the Program?" mdxType="Collapsible">
        <ul>
          <li parentName="ul">{`Get early access to `}<Brand mdxType="Brand" /></li>
          <li parentName="ul">{`Have your medicines delivered to your doorstep`}</li>
          <li parentName="ul">{`Receive patient education materials and on-demand access to nurse educators to learn more about how to use `}<Brand mdxType="Brand" />{` and manage your condition`}</li>
        </ul>
      </Collapsible>
      <Collapsible title="What medicines will I receive?" mdxType="Collapsible">
        <p>{`Eligible patients under the `}<ProgramName mdxType="ProgramName" />{` will receive one (1) Semaglutide 0.25mg Pre-filled Pen and two (2) Semaglutide 0.5mg Pre-filled Pens which is equivalent for three months treatment with `}<Brand mdxType="Brand" />{`. If your doctor prescribed in excess of this amount, you can purchase additional pens once `}<Brand mdxType="Brand" />{` becomes commercially available. `}</p>
      </Collapsible>
      <Collapsible title="When will the program end?" mdxType="Collapsible">
        <p>{`Upon completion of the program three months after receiving the samples, patients will need to obtain a new prescription from their doctor for their continued treatment, and will be able to purchase the medicine in major pharmacies nationwide. Patients can continuously get in touch with the Nurse Educator after the Early Experience Program for further diabetes education and support.`}</p>
      </Collapsible>
      <h4>{`Privacy`}</h4>
      <Collapsible title="What information do you share?" mdxType="Collapsible">
        <p>{`Only our pharmacists processing your enrollment have access to your medical information to ensure your medicines are properly dispensed. We may use anonymized data for analytics to improve our services or collaborate with partners.`}</p>
      </Collapsible>
      <Collapsible title="Who will have access to my data?" mdxType="Collapsible">
        <p>{`MedGrocer may collect and process certain personal information for your enjoyment of the benefits of the program.  All personal information obtained will be handled with full and strict confidentiality in compliance with the Republic Act No. 10173 or the “Data Privacy Act of 2012”.`}</p>
        <p>{`You have the right to access, correct, update, and object to the processing of your personal information at any time by submitting a written request to MedGrocer’s Data Privacy Officer.`}</p>
      </Collapsible>
      <Collapsible title="How do I deactivate my account or contact the data privacy officer?" mdxType="Collapsible">
        <p>{`MedGrocer’s Data  Protection Officer provides support with any data privacy related questions, comments, concerns, or complaints. You may contact MedGrocer’s Data Privacy Officer through the following information:`}</p>
        <p>{`MedGrocer 24th Floor, Centuria Medical Makati Building, Gen. Luna Street, corner Salamanca Street, Makati City `}<br />{`
Contact Number: +632 8332 3768 `}<br />{`
Email address: `}<a href="mailto:dpo@medgrocer.com">{`dpo@medgrocer.com`}</a></p>
      </Collapsible>
      <h4>{`Regulatory`}</h4>
      <Collapsible title="What is MedGrocer?" mdxType="Collapsible">
        <p>{`MedGrocer is an FDA-licensed online pharmacy. Simply order your medicines online and we’ll deliver them to you.`}</p>
      </Collapsible>
      <Collapsible title="Are your medicines FDA-certified?" mdxType="Collapsible">
        <p>{`Yes. The Certificate of Product Registration can be found at the website of the Food and Drug Administration.`}</p>
      </Collapsible>
      <ContactUs mdxType="ContactUs" />
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      